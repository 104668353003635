define('main.js',['require','jquery','./js/sendAnalytics'],function (require) {
    'use strict';

    var $;
    $ = require('jquery');

    // XRAY
    var sendAnalytics = require('./js/sendAnalytics');

    var isAuthed = 'authid';
    if (new RegExp('[?&]' + encodeURIComponent(isAuthed) + '=([^&]*)').exec(location.search)) {
        sendAnalytics('authorization-success');
    } else {
        sendAnalytics('landing-view');
    }
    var xrayLinks = Array.from(document.querySelectorAll('[data-xray]'));
    xrayLinks.forEach(function (link) {
        link.addEventListener('click', function () {
            try {
                sendAnalytics(link.dataset.xray);
            } catch (e) {
                console.log(e);
            }
        });
    });

    // popup
    var popupService = {
        inputs: document.querySelectorAll('.restoration-upload [name="file"]'),
        popup: document.getElementById('restoration-popup'),
        popupClose: document.querySelector('.restoration-popup__close'),
        preview: document.querySelectorAll('.restoration-preview'),
        mainPreviewEl: '.restoration-preview__main img',
        thumbsWrapperEl: '.restoration-preview__photos',
        thumbsEls: '.restoration-preview__photo img',
        popupInfo: document.querySelectorAll('.restoration-info'),
        uploadLink: document.querySelectorAll('[data-xray="click-save-cloud"]'),
        filename: '',

        lexicon: {
            titleLoading: 'Улучшаем фотографию',
            descrLoading: 'Подождите немного',
            titleSuccess: 'Фото готово',
            descrSuccess: 'Сохраните его в&nbsp;Облако&nbsp;&mdash; а&nbsp;затем скачайте или поделитесь',
            errors: {
                fail: 'Что-то пошло не&nbsp;так.<br>Пожалуйста, попробуйте загрузить фотографию еще раз',
                filesizeLimitExceeded: 'Слишком тяжелый файл.<br>Пожалуйста, загрузите фотографию менее 8 МБ',
                invalidFormat: 'Допустимый формат файлов&nbsp;&mdash;&nbsp;.jpeg, .png, .webp, .bmp, .tiff',
            },
        },

        popupPreviewEl: '.restoration-popup__title',
        popupDescriptionEl: '.restoration-popup__description',
        preloader: document.querySelector('.restoration-loader'),
        visibleClass: '--visible',
        popupActive: '_popup-active',
        delay: 10000,

        init: function () {
            var self = this;
            self.getStorage();

            for (var i = 0; i < self.uploadLink.length; i++) {
                self.uploadLink[i].addEventListener('click', function () {
                    self.saveToCloud(self.filename);
                });
            }

            if (!self.inputs || !self.popup) {
                return;
            }
            self.switcherPhoto();

            for (var i = 0; i < self.inputs.length; i++) {
                self.inputs[i].addEventListener('click', function (e) {
                    e.target.value = '';
                });
            }
            for (var i = 0; i < self.inputs.length; i++) {
                self.inputs[i].addEventListener('change', function (e) {
                    var file = e.target.files[0];
                    self.hideError();

                    if (file.size > 8 * 1024 * 1024) {
                        self.showError('filesizeLimitExceeded');
                        return;
                    }

                    // short mime check
                    if (file.type && !file.type.match(/jpe?g|png|tiff|bmp|webp/)) {
                        self.showError('invalidFormat');
                        return;
                    }

                    self.openPopup();
                    var reader = new FileReader();
                    reader.onload = function () {
                        self.getImages(e.target, function (response) {
                            var body = response.body;
                            var isVisionResponse = body && body.objects && body.objects.length;

                            if (!isVisionResponse) {
                                self.hidePopup();
                                for (var i = 0; i < self.popupInfo.length; i++) {
                                    self.popupInfo[i].innerHTML = self.lexicon.errors.fail;
                                    self.popupInfo[i].style.display = 'block';
                                }
                            } else {
                                if (response.status === 200 && body.status === 0) {
                                    sendAnalytics('download-success');

                                    var objects = {
                                        bw: body.objects[0].bw && body.objects[0].bw,
                                        name: body.objects[0].name && body.objects[0].name,
                                        improved: body.objects[0].improved && 'data:image/jpeg;base64,' + body.objects[0].improved,
                                        colorizedImproved:
                                            body.objects[0].colorized_improved &&
                                            'data:image/jpeg;base64,' + body.objects[0].colorized_improved,
                                        colorized: body.objects[0].colorized && 'data:image/jpeg;base64,' + body.objects[0].colorized,
                                    };
                                    self.filename = objects.name;
                                    self.setStorage(objects);

                                    self.hideError();
                                    for (var a = 0; a < self.preview.length; a++) {
                                        self.showLoadScreen(
                                            a,
                                            objects.name,
                                            objects.bw,
                                            objects.improved,
                                            objects.colorizedImproved,
                                            objects.colorized
                                        );
                                    }
                                }
                            }
                        });
                    };
                    reader.readAsDataURL(file);
                });
            }

            self.popupClose.addEventListener('click', function () {
                self.hidePopup();
            });
        },

        getImages: function (input, cb) {
            var formData = new FormData();
            formData.append('image[]', input.files[0]);
            $.ajax({
                url: 'https://9may.mail.ru/photo/',
                type: 'POST',
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
            }).then(cb, cb);
        },

        setStorage: function (imagesList) {
            return localStorage.setItem('vision_result_images', JSON.stringify(imagesList));
        },

        getStorage: function () {
            var self = this;
            var r = localStorage.getItem('vision_result_images');
            if (r) {
                var res = JSON.parse(r);
                self.filename = res.name;
                for (var a = 0; a < self.preview.length; a++) {
                    self.showLoadScreen(a, res.name, res.bw, res.improved, res.colorizedImproved, res.colorized);
                }
            }

            if (new RegExp('[?&]' + encodeURIComponent(isAuthed) + '=([^&]*)').exec(location.search)) {
                var img = new Image();
                img.src = res.colorized;
                img.onload = function () {
                    var active = localStorage.getItem('vision_result_active');
                    if (active) {
                        for (var a = 0; a < self.preview.length; a++) {
                            self.preview[a].querySelectorAll(self.thumbsEls)[active].click();
                        }
                    }
                    self.saveToCloud(self.filename);
                };
            }
        },

        clearStorage: function () {
            return localStorage.removeItem('vision_result_images');
        },

        showLoadScreen: function (index, name, bw, improved, colorizedImproved, colorized) {
            var self = this;
            var img = new Image();
            img.src = colorized;

            var main = self.preview[index].querySelector(self.mainPreviewEl);
            var thumbs = self.preview[index].querySelectorAll(self.thumbsEls);

            img.onload = function () {
                if (bw === false) {
                    // if coloring photo
                    if (improved) {
                        // if 2 photos
                        main.src = improved;
                        self.addToPreviewContainer(thumbs, [improved, colorizedImproved, colorized]);
                        self.preview[index].querySelector(self.thumbsWrapperEl).classList.add(self.visibleClass);
                    } else {
                        main.src = colorized;
                        self.preview[index].querySelector(self.thumbsWrapperEl).classList.remove(self.visibleClass);
                        for (var i = 0; i < thumbs.length; i++) {
                            thumbs[i].src = '';
                        }
                    }
                } else {
                    // if black'n'white
                    if (colorizedImproved) {
                        // if 2 photos
                        main.src = colorizedImproved;
                        self.addToPreviewContainer(thumbs, [colorizedImproved, colorized, improved]);
                        self.preview[index].querySelector(self.thumbsWrapperEl).classList.add(self.visibleClass);
                    } else {
                        main.src = colorized;
                        self.preview[index].querySelector(self.thumbsWrapperEl).classList.remove(self.visibleClass);
                        for (var i = 0; i < thumbs.length; i++) {
                            thumbs[i].src = '';
                        }
                    }
                }
                self.uploadLink[index].disabled = false;
                self.preloader.classList.remove(self.visibleClass); // hide loading
                self.preview[1].classList.add(self.visibleClass); // show preview
                self.popup.querySelector(self.popupPreviewEl).innerHTML = self.lexicon.titleSuccess;
                self.popup.querySelector(self.popupDescriptionEl).innerHTML = self.lexicon.descrSuccess;

                document.querySelector('.restoration-main__btns.--default').style.display = 'none';
                document.querySelector('.restoration-main__btns.--success').style.display = 'flex';
            };
        },

        addToPreviewContainer: function (thumbs, imgs) {
            for (var i = 0; i < thumbs.length; i++) {
                if (imgs[i]) {
                    thumbs[i].src = imgs[i];
                }
            }
        },

        switcherPhoto: function () {
            var self = this;
            var activeClass = '_active';

            for (var i = 0; i < self.preview.length; i++) {
                var thumbs = self.preview[i].querySelectorAll(self.thumbsEls);
                Array.from(thumbs).forEach(function (thumb, idx, arr) {
                    if (!idx) {
                        // первый элемент
                        thumb.parentElement.classList.add(activeClass);
                        self.preview[i].querySelector(self.mainPreviewEl).src = thumb.src;
                    }
                    thumb.onclick = function (e) {
                        for (var x = 0; x < arr.length; x++) {
                            arr[x].parentElement.classList.remove(activeClass);
                        }
                        e.target.closest('.restoration-preview').querySelector(self.mainPreviewEl).src = thumb.src;
                        thumb.parentElement.classList.add(activeClass);
                        localStorage.setItem('vision_result_active', idx);
                    };
                });
            }
        },

        showError: function (errType) {
            var self = this;
            self.hideError();
            for (var i = 0; i < self.popupInfo.length; i++) {
                self.popupInfo[i].innerHTML = self.lexicon.errors[errType];
                self.popupInfo[i].style.display = 'block';
            }
        },

        hideError: function () {
            var self = this;
            for (var i = 0; i < self.popupInfo.length; i++) {
                self.popupInfo[i].innerText = '';
                self.popupInfo[i].style.display = 'none';
            }
        },

        openPopup: function () {
            var self = this;
            self.popup.querySelector(self.popupPreviewEl).innerHTML = self.lexicon.titleLoading;
            self.popup.querySelector(self.popupDescriptionEl).innerHTML = self.lexicon.descrLoading;
            self.popup.classList.add(self.visibleClass);
            document.documentElement.classList.add(self.popupActive);
            self.preloader.classList.add(self.visibleClass);
            self.preview[1].classList.remove(self.visibleClass);
        },

        hidePopup: function () {
            var self = this;
            self.popup.classList.remove(self.visibleClass);
            document.documentElement.classList.remove(self.popupActive);
            self.preloader.classList.remove(self.visibleClass);
            self.preview[1].classList.remove(self.visibleClass);
            self.popup.querySelector(self.popupPreviewEl).innerHTML = '';
            self.popup.querySelector(self.popupDescriptionEl).innerHTML = '';
        },

        saveToCloud: function (filename) {
            var self = this;
            var photo = self.preview[0].querySelector(self.mainPreviewEl).src;
            var blob = undefined;
            fetch(photo)
                .then(function (response) {
                    blob = response.blob();
                    return blob;
                })
                .then(function (blob) {
                    window.saveFileToCloud(blob, 'Воспоминания', filename + '.jpg');
                })
                .catch();
        },
    };

    popupService.init();
});

