define('js/sendAnalytics',['require','exports','module'],function(require,  exports, module) {
    'use strict';

    function sendTmrGoal(action) {
        //console.debug('9-may _tmr reachGoal: ', action);
        var _tmr = window._tmr || (window._tmr = []);
        try {
            _tmr.push({
                id: '3502704',
                type: 'reachGoal',
                goal: action
            });
        }
        catch (e) { console.log(e); }
    }
    
    var sendAnalytics = function sendAnalytics(action) {
        window.sendNineMayAnalytics && window.sendNineMayAnalytics({
            action: action
        });
        sendTmrGoal(action);
    };

    module.exports = sendAnalytics;
});

